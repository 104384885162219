body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
}
#map {
  width: 100vw;
  height: 100vh;
}

.side-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
}

.map-panel {
  background: rgba(28, 28, 31, 0.9);
  color: rgb(217, 217, 217);
  box-shadow: 0 2px 4px rgba(141, 141, 141, 0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  outline: none;
  width: 300px;
}

.map-panel h3 {
  text-transform: uppercase;
}

.map-panel.info {
  display: none;
}

label {
  display: inline-block;
  width: 150px;
}

input {
  margin-left: 20px;
}

.input.disabled {
  opacity: .5;
  cursor: not-allowed;
}

.control-info {
  display: none;
}

@media screen and (min-width:450px) {
  /* Bei jedem größeren Monitor kommen neue Regeln hinzu */
  .map-panel.info {
    display: block;
  }
  .control-info {
    display: block;
  }

  .side-panel {
    margin-right: 10px;
    width: 380px;
    height: 100vh;
  }
}